<template>
  <ContentWrapper
      :permissions="[`${moduleName.toLowerCase()}-${subModuleName.toLowerCase()}-read`]"
      :wrapper-background="false"
  >
    <LayoutGenerator
        :schema="schema"
        v-if="additionalPropsAreSet"
    />
  </ContentWrapper>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import ContentWrapper from '../../components/HOCs/ContentWrapper.vue';

export default {
  name: 'Entity',
  components: {
    ContentWrapper,
    LayoutGenerator: () => import('../../components/LayoutGenerator/LayoutGenerator.vue'),
  },
  props: ['moduleName', 'subModuleName', 'formId', 'entityId'],
  data() {
    return {
      schema: null,
      additionalPropsAreSet: false,
    };
  },
  async beforeMount() {
    await this.fetchLayoutSchemas(this.$route.params.moduleName);
    await this.fetchSchema();
  },
  watch: {
    '$route.path'(val) {
      this.additionalPropsAreSet = false;
      this.schema = null;
      this.fetchSchema();
    },
    async isNecessaryDataFetched(val) {
      if (val) {
        this.setAdditionalProps();
      }
    },
  },
  computed: {
    ...mapGetters('modules', [
      'getPathToForm',
      'findSubmodule',
    ]),
    ...mapState('modules', ['modules']),
    isNecessaryDataFetched() {
      return !!(this.modules && this.modules.length && this.schema);
    },
    // pathToForm() {
    //   return this.getPathToForm(Number(this.formId))
    // }
  },
  methods: {
    ...mapActions('modules', ['getSchemaByFormId', 'fetchLayoutSchemas']),
    async fetchSchema() {
      this.schema = await this.getSchemaByFormId(this.formId);
    },
    setAdditionalProps() {
      this.additionalPropsAreSet = false;
      this.schema = this.schema.map(column => {
        return {
          ...column,
          components: column.components
              .filter(component => {
                if (this.entityId === 'create') {
                  return component.type === 'MainComponent';
                }
                return true;
              })
              .map(component => {
                let formId = null;
                if (component.props && component.props.submoduleName) {
                  const submodule = this.findSubmodule(this.moduleName, component.props.submoduleName);
                  if (submodule && submodule.forms) {
                    formId = submodule.forms[0].id;
                  }
                }
                return {
                  ...component,
                  props: {
                    ...component.props,
                    overlaySubmoduleName: this.subModuleName,
                    overlayModuleName: this.moduleName,
                    overlayEntityId: this.entityId === 'create' ? this.entityId : Number(this.entityId),
                    overlayEntityFormId: Number(this.formId),
                    formId,
                    dataFetchParams: {
                      form_id: formId,
                      entity_id: Number(this.entityId),
                      entity_form_id: Number(this.formId),
                    },
                  },
                };
              }),
        };
      });
      this.additionalPropsAreSet = true;
    },
  },
};
</script>
